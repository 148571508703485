import React, { Component } from 'react';
import {
  createMuiTheme,
  MuiThemeProvider, withStyles
} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import configurations from "../../configurations";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import styles from "../../Assets/CSS/stylesheet_UHS";
import axios from "axios";
import AIKB from './AIKB';




const StyleTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "#ffffff",
    fontSize: '13px'
  }
})(Tooltip);


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '0px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const NextButton = withStyles(
  // customStyle.doneBtn
  customStyle.doneNetwellBtn
)(Button);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: '16px',
    paddingRight: '0px',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

// export default function CustomizedAccordions() {
//   const [expanded, setExpanded] = React.useState('panel1');

//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };
class CustomizedAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: 'panel2',
      accordionData: [],
      agentURL: '',
      tooltipShow: false,
      isAgent: true,
      isMember: true,
      isEmployer: true
    }
  }

  componentDidMount() {

    let data = {
      "clientId": this.props.clientId
    }
    axios.post(configurations.baseUrl + '/enrollment/getClient', data)
      .then(response => {
        if (response.data.response) {
          let clientName = response.data.response.clientName.replaceAll(' ', '');
          let url = configurations.AGENT_BASE_URL + clientName + '/' + this.props.agentId
          if (this.props.empid) {
            url = url + '/' + this.props.empid
          }
          this.setState({
            agentURL: url
          })

        }
      });


    fetch(configurations.baseUrl + '/agentlogin/organizationDocList/' + this.props.clientId + '/Netwell')
      .then(response => response.json())
      .then(data => {
        console.log("Accordian data---", data.response)
        this.setState({ accordionData: data.response })
      });

      fetch(configurations.baseUrl + '/employer/employerOrganizationDocList')
      .then(response => response.json())
      .then(data => {
        console.log("Accordian data emp---", data.response.LearingList[0].data)
        this.setState({ accordionDataEmployer: data.response.LearingList[0].data })
      }, () => console.log(' - - - -0000',this.state.accordionDataEmployer));
  }
  handleChange = panel => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false })
  }

  copy = (e) => {
    if (this.state.agentURL !== '') {
      // this.state.agentURL.select();
      document.execCommand("copy");
      e.target.focus();
      this.setState({ tooltipShow: true });
      this.hideTooltip()
    }
  }

  hideTooltip = () => {
    setInterval(() => {
      this.setState({
        tooltipShow: false
      })
    }, 5000);
  }


  render() {
    console.log(' - - - -0000',this.state.accordionDataEmployer)

    return (
      <div style={{ fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}>
        <Accordion square expanded={this.state.isEmployer} onChange={() => this.setState({ isEmployer: !this.state.isEmployer })} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
            <Typography component={'span'} style={customStyle.accordioanLabel}>Employer Resources</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ maxHeight: '500px', overflowX: 'hidden', overflowY: 'auto' }}>
            <Grid item xs={6} sm={6} lg={12}>
                  {this.state.accordionDataEmployer &&
                  <Typography key={'newL'} component={'ul'} style={{ listStyleType: "none", paddingLeft: '5px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", fontSize: '14px', marginBottom: "9px" }}>
                  {/* <span style={customStyle.accordioanLabel}>{this.state.accordionDataEmployer.header}</span> */}
                      <Typography component={'li'} style={{ paddingLeft: '10px', margin: '5px' }}>
                        <a href={this.state.accordionDataEmployer.docLink} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer', marginRight: '7px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", fontSize: '14px' }} target='_blank'>
                          {this.state.accordionDataEmployer.title}
                        </a>
                      </Typography>
                </Typography>}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={this.state.isMember} onChange={() => this.setState({ isMember: !this.state.isMember })} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
            <Typography component={'span'} style={customStyle.accordioanLabel}>Member & Prospect Resources</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ maxHeight: '500px', overflowX: 'hidden', overflowY: 'auto' }}>
            <Grid item xs={6} sm={6} lg={12}>
              {(this.state.accordionData && this.state.accordionData.MarketingList) &&
                this.state.accordionData.MarketingList.map((op, index) => (
                  <Typography key={'newL' + index} component={'ul'} style={{ listStyleType: "none", paddingLeft: '5px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", fontSize: '14px', marginBottom: "9px" }}>
                    <span key={index} style={customStyle.accordioanLabel}>{op.Header}</span>
                    {
                      op.data.map((key, index) => (
                        <Typography component={'li'} style={{ paddingLeft: '10px', margin: '5px' }}>
                          <a href={key.docLink} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer', marginRight: '7px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", fontSize: '14px' }} target='_blank'>
                            {key.title}
                          </a>

                        </Typography>
                      ))
                    }
                  </Typography>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>

      </div>
    );
  }
}

export default CustomizedAccordions;
import React, { Component } from 'react'
import Grid from "@material-ui/core/Grid";
import AccordonCommon from "../../CommonScreens/AccordonCommon";
import { Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import styles from "../../../Assets/CSS/stylesheet_UHS";
import Loader from "../../loader";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Sample from "../../CommonScreens/sampleTextField";
import axios from "axios";
import configurations from "../../../configurations";
import AgentTable from '../../CommonScreens/AgentTable';
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Header from "../Headers/Header";
import Footer from "../../CommonScreens/Footer";
import { connect } from "react-redux";
import { getAgentDetails, getQueryParams } from '../../authentication/utils';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from "moment";
import CommonDropDwn from "../../CommonScreens/CommonDropDwn_1";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SignScroll from "./SignScroll";
import TextSignature from "./TextSignature";
import customeClasses from "./SubmitApplication.css";
import html2canvas from "html2canvas";
import Platform from 'react-platform-js';
/* For edit employer modal */
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import { createMuiTheme, withStyles, MuiThemeProvider } from "@material-ui/core/styles";

// import EditEmployer from './EditEmployer'
var convert = require('xml-js');

const CssTextField = withStyles(theme => ({
    root: {
        '& .MuiInput-root': {
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: '2px solid #533278'
            },

            '&.MuiInput-underline.Mui-focused:after': {
                borderBottom: '2px solid #533278',
            },

        }
    },
}))(TextField);

/* end */



const GenBtn1 = withStyles(
    customStyle.genrateBtn1
)(Button);

const NextButton = withStyles(
    styles.doneBtn
)(Button);

const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);
const CrudButton = withStyles(
    styles.crudBtnAgent,
)(Fab);
const NextButton1 = withStyles(
    styles.doneBtnAgent
)(Button);
const PurpleRadio = withStyles(customStyle.radioBtn)((props) => (
    <Radio color="default" {...props} />
));

var formData = new FormData();
formData.append("draw", "1");
formData.append("start", "0");
formData.append("length", "10");
formData.append("columns[0].data", "id");
formData.append("columns[0].name", "");
formData.append("columns[0].searchable", "true");
formData.append("columns[0].orderable", "true");
formData.append("columns[0].search.regex", "false");
formData.append("columns[0].search.value", "");

formData.append("columns[1].data", "createdDate");
formData.append("columns[1].name", "");
formData.append("columns[1].searchable", "true");
formData.append("columns[1].orderable", "true");
formData.append("columns[1].search.regex", "false");
formData.append("columns[1].search.value", "");

formData.append("columns[2].data", "email");
formData.append("columns[2].name", "");
formData.append("columns[2].searchable", "true");
formData.append("columns[2].orderable", "true");
formData.append("columns[2].search.regex", "false");
formData.append("columns[2].search.value", "");

formData.append("columns[3].data", "enrollFlag");
formData.append("columns[3].name", "");
formData.append("columns[3].searchable", "true");
formData.append("columns[3].orderable", "false");
formData.append("columns[3].search.regex", "false");
formData.append("columns[3].search.value", "");

formData.append("columns[4].data", "firstName");
formData.append("columns[4].name", "");
formData.append("columns[4].searchable", "true");
formData.append("columns[4].orderable", "true");
formData.append("columns[4].search.regex", "false");
formData.append("columns[4].search.value", "");

formData.append("columns[5].data", "lastName");
formData.append("columns[5].name", "");
formData.append("columns[5].searchable", "true");
formData.append("columns[5].orderable", "true");
formData.append("columns[5].search.regex", "false");
formData.append("columns[5].search.value", "");

formData.append("columns[6].data", "completionStatus");
formData.append("columns[6].name", "");
formData.append("columns[6].searchable", "true");
formData.append("columns[6].orderable", "false");
formData.append("columns[6].search.regex", "false");
formData.append("columns[6].search.value", "");

formData.append("columns[7].data", "empid");
formData.append("columns[7].name", "");
formData.append("columns[7].searchable", "true");
formData.append("columns[7].orderable", "false");
formData.append("columns[7].search.regex", "false");
formData.append("columns[7].search.value", "");

formData.append("columns[8].data", "record");
formData.append("columns[8].name", "");
formData.append("columns[8].searchable", "true");
formData.append("columns[8].orderable", "true");
formData.append("columns[8].search.regex", "false");
formData.append("columns[8].search.value", "");

formData.append("columns[9].data", "inviteStatus");
formData.append("columns[9].name", "");
formData.append("columns[9].searchable", "true");
formData.append("columns[9].orderable", "true");
formData.append("columns[9].search.regex", "false");
formData.append("columns[9].search.value", "");

formData.append("order[0].column", "0");
formData.append("order[0].dir", "desc");
formData.append("search.regex", "false");
formData.append("search.value", "");

let requestOptions = {
    method: 'POST',
    body: formData,
};

class Prospect extends Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const tomorrow = new Date(today);

        this.state = {
            // USER_DATA: USER_DATA,
            userList: [],
            headerList: ["User Id", "User Name", "Email Id", "Status"],
            modalShow: false,
            loaderShow: false,
            mailModal: false,
            bid: '',
            cid: '',
            aid: '',
            url: '',
            fname: '',
            lname: '',
            email: '',
            phone: '',
            genrate: false,
            openMailModal: false,
            countryCode: '',
            userValid: false,
            openMenu: null,
            msgModal: false,
            msgModal1: false,
            errMsg: "",
            USER_DATA: {},
            empid: '',
            countData: {},
            viewEmployerModal: false,
            employerDetailsObj: [],
            emp_email_id: null,
            confirmationModal: false,
            selectedEmail: '',

            editEmp: false,

            showAuthWindow: false,
            disableAuthSubmit: true,
            authName: '',
            compName: '',
            zipCode: '',
            state: '',
            city: '',
            street: '',
            division: '',
            industry: '',
            codeTitle: '',
            code: '',
            firstName: '',
            lastName: '',
            workEmail: '',
            jobTitle: '',
            phone: '',
            divisionList: [],
            industryList: [],
            codeTitleList: [],
            codeList: [],
            countryCode: '',
            effectiveDate: new Date(tomorrow),
            dateErr: false,
            birthDtFocus: false,
            birthDt: false,
            adminPhone: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            adminCountryCode: '',
            // empid: '',
            page: 0,
            copyEmpCheck: false,
            sendQuoteFlag: false,
            sendQuoteError: [],
            payrollCycle: 'Weekly',
            employerContribution: null,
            contributionType: 'Doller',
            accountTypes: [],
            bankName: '',
            accountName: '',
            routingNo: '',
            accountNumber: '',
            accountType: '',
            signType: "stylized",
            isLoaded: false,
            isAcknowledged: true,
            invoiceList: [],
            adviceList: [],
            showInvoiceList: false,
            showAdviceList: false,
            fullName: '',
            canvasDone: false
        }
        this.child = React.createRef();
    }

    invoiceColumns = [
        {
            label: "Date Added", name: "createdDate", options: {
                sort: false,
                customBodyRenderLite: (index) => {
                    if (this.state.invoiceList && this.state.invoiceList[index] && this.state.invoiceList[index].invoiceDate) {
                        return (
                            moment.utc(this.state.invoiceList[index].invoiceDate).format('MMMM DD, YYYY')
                            )
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                label: "Invoice", name: "invoiceLink", options: {
                    sort: false,
                    customBodyRenderLite: (index) => {
                        if (this.state.invoiceList && this.state.invoiceList[index] && this.state.invoiceList[index].url) {
                            return (
                                <text style={{ cursor: 'pointer', marginBottom: 40, textDecoration: 'underline', color: 'blue' }} onClick={() => this.setState({ showInvoice: true, invoiceUrl: this.state.invoiceList[index].url })}>Invoice Link</text>
                            )
                        } else {
                            return 'NA';
                        }
                    }
                }
            },
    ];

    invoiceOptions = {
        selectableRows: false,
        filter: false,
        selectableRowsHeader: false,
        count: 0,
        rowsPerPage: 0,
        search: false,
        serverSide: true,            
        viewColumns: false,
        print: false,
        fixedHeader: true,
        page: 0,
        download: false,
        customFooter: () => {
            return <div></div>;
          }
    };

    adviceColumns = [
        {
            label: "Date Added", name: "createdDate", options: {
                sort: false,
                customBodyRenderLite: (index) => {
                    if (this.state.adviceList && this.state.adviceList[index] && this.state.adviceList[index].createdDate) {
                        return (
                            moment.utc(this.state.adviceList[index].createdDate).format('MMMM DD, YYYY')
                            )
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                label: "Advice", name: "adviceLink", options: {
                    sort: false,
                    customBodyRenderLite: (index) => {
                        if (this.state.adviceList && this.state.adviceList[index] && this.state.adviceList[index].advoiceUrl) {
                            return (
                                <text style={{ cursor: 'pointer', marginBottom: 40, textDecoration: 'underline', color: 'blue' }} onClick={() => this.setState({ showAdvice: true, adviceUrl: this.state.adviceList[index].advoiceUrl })}>Advice Link</text>
                            )
                        } else {
                            return 'NA';
                        }
                    }
                }
            },
    ];

    adviceOptions = {
        selectableRows: false,
        filter: false,
        selectableRowsHeader: false,
        count: 0,
        rowsPerPage: 0,
        search: false,
        serverSide: true,            
        viewColumns: false,
        print: false,
        fixedHeader: true,
        page: 0,
        download: false,
        customFooter: () => {
            return <div></div>;
          }
    };

    async componentDidMount() {
        let ipAddress = null
        await axios.get('https://geolocation-db.com/json/')
            .then(res => {
                console.log(res.data.IPv4);
                ipAddress = res.data.IPv4;
            })
        let empEmail = sessionStorage.getItem('empEmail');
        console.log(empEmail);
        this.setState({
            loaderShow: true,
            empEmail
        })

        axios.get(process.env.REACT_APP_BASE_URL + `/employer/getEmployerByEmail/${empEmail}`)
            .then(response => {
                let divisionList = [];
                console.log('  - - 0 0 0 ', response.data.response);
                this.setState({
                    loaderShow: false,
                    empDetails: response.data.response,
                    isAcknowledged: response.data.response.acknowledgement,
                    brokerId: response.data.response.brokerId,
                    isLoaded: this.textSignatureToImage,
                    empid: response.data.response.empid,
                    USER_DATA: { empid: response.data.response.empid }
                })

                // axios.get(process.env.REACT_APP_EMP_BASE_URL + `/employerService/api/v25/invoice-list/Kenneth191`)
                    axios.get(process.env.REACT_APP_EMP_BASE_URL + `/employerService/api/v25/invoice-list/${response.data.response.empid}`)
                    .then(res => {
                        this.setState({ invoiceList: res.data })
                    })
                    .catch(err => console.log(err))

                    axios.get(process.env.REACT_APP_EMP_BASE_URL + `/employerService/api/v25/advoiceList/${response.data.response.empid}`)
                    .then(res => {
                        this.setState({ adviceList: res.data })
                    })
                    .catch(err => console.log(err))

                axios.get(configurations.baseUrl + "/agentlogin/getAgentById/" + response.data.response.brokerId)
                    .then((response) => {
                        console.log("desc===", response)
                        if (response.data.response) {
                            console.log('res - - - ', response.data.response);
                            sessionStorage.setItem('USER_DATA', JSON.stringify({ agentId: response.data.response.brokerId, clientId: response.data.response.clientId, associationId: response.data.response.associationId, clientName: response.data.response.clientName, defaultEmpid: response.data.response.defaultEmpid ? response.data.response.defaultEmpid : '' }))
                            this.setState({
                                USER_DATA: { ...this.state.USER_DATA, agentId: response.data.response.brokerId, clientId: response.data.response.clientId, associationId: response.data.response.associationId, clientName: response.data.response.clientName, defaultEmpid: response.data.response.defaultEmpid ? response.data.response.defaultEmpid : '' }
                            })
                        }
                    })

                this.getEmpCounts(response.data.response.empid)

                const date = new Date();
                const dateAsString = date.toString();
                const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
                var matches = timezone.match(/\b(\w)/g);
                var abbreviations = matches.join('');

                let userData = {
                    empid: response.data.response.empid,
                    timeZone: abbreviations,
                    ipAddress,
                    browser: Platform.Browser,
                    device: Platform.DeviceType || "computer",
                    os: Platform.OS
                }

                console.log('data88', userData);
                axios.post(configurations.baseUrl + '/employer/employerLoginDetails', userData)
                    .then(res => console.log(res))
            })

        axios.get(configurations.baseUrl + '/employer/employerOrganizationDocList')
            .then(response => response)
            .then(data => {
                console.log("Accordian data emp---", data.data.response.LearingList[0].data.docLink)
                this.setState({ pdfLink: data.data.response.LearingList[0].data.docLink })
            });


        /* Edit employer */
        let obj = {
            "searchKey": "division",
            "searchValue": ""
        }
        axios.post(process.env.REACT_APP_BASE_URL + '/employer/getSIC', obj)
            .then(response => {
                let divisionList = [];
                for (let i = 0; i < response.data.response.length; i++) {
                    divisionList.push({
                        key: response.data.response[i].division,
                        value: response.data.response[i].division
                    });

                }
                this.setState({
                    divisionList: divisionList
                })
            })

        /* end */


        window.addEventListener('REFRESH', this.getEmpCounts());

        fetch(configurations.enrollmentBaseURL + "/setuppayment/getAccountType")
            .then((response) => response.json())
            .then((response) => {
                this.setState({
                    accountTypes: response.response,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    refreshCount = (e) => {
        // console.log("count refresh---", e.detail)
        // this.getTotalEmpInfo()
    }

    getEmpCounts = (empId) => {
        axios.get(configurations.baseUrl + '/enrollment/getCountsFormEmployer/' + empId)
            .then(response => {
                console.log('empres', response);
                if (response.data.code === 200) {
                    this.setState({
                        countData: response.data.response,
                        // USER_DATA: JSON.parse(sessionStorage.getItem('USER_DATA')),
                        loaderShow: false
                    }, () => console.log("Userdata---", this.state.countData))
                }
            })
    }
    //update employer details namita

    addEmployer = () => {
        this.setState({
            loaderShow: true
        })
        // let URL = process.env.REACT_APP_BASE_URL + '/employer/addEmployer';


        let obj = {
            "id": this.state.id,
            "companyName": this.state.compName,
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "jobTitle": this.state.jobTitle,
            "phone": this.state.countryCode + this.state.phone,
            "zip": this.state.zipCode,
            "state": this.state.state,
            "city": this.state.city,
            "street": this.state.street,
            "effective_date": moment(this.state.effectiveDate).format('YYYY-MM-DD'),
            "brokerId": this.state.USER_DATA.agentId,
            "division": this.state.division,
            "industry": this.state.industry,
            "codeTitle": this.state.codeTitle,
            "code": this.state.code,
            adminFirstName: this.state.adminFirstName,
            adminLastName: this.state.adminLastName,
            adminPhone: this.state.adminCountryCode + this.state.adminPhone,
            adminEmail: this.state.adminEmail,
            email: this.state.workEmail,
            "payrollCycle": this.state.payrollCycle,
            "contributionType": this.state.contributionType,
            "employerContribution": parseFloat(this.state.employerContribution) || 0,
            "employerPayment":
            {
                "bankName": this.state.bankName,
                "accountName": this.state.accountName,
                "accountNumber": this.state.accountNumber,
                "accountType": this.state.accountType,
                "routingNo": this.state.routingNo,

            }
        }

        // if (this.state.employerEdit) {
        URL = process.env.REACT_APP_BASE_URL + '/employer/updateEmployer';
        obj.empid = this.state.empid
        // }

        axios.post(URL, obj)
            .then(response => {
                let errMsg = '';
                if (response.data.code === 200) {
                    errMsg = 'Employer Updated Successfully !'
                } else {
                    errMsg = response.data.message
                }
                let today = new Date();
                let tomorrow = new Date(today);

                // tomorrow.setDate(tomorrow.getDate() + 1);
                //    let efectiveDate = this.state.effectiveDate ? new Date(this.state.effectiveDate) : new Date();


                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 1);

                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 1);

                this.state.countData.companyName = this.state.compName
                this.setState({
                    msgModal: true,
                    loaderShow: false,
                    // addEmployerModal: false,
                    viewEmployerModal: false,
                    errMsg: errMsg,
                    compName: '',
                    zipCode: '',
                    state: '',
                    city: '',
                    street: '',
                    division: '',
                    industry: '',
                    codeTitle: '',
                    code: '',
                    firstName: '',
                    lastName: '',
                    workEmail: '',
                    jobTitle: '',
                    phone: '',
                    industryList: [],
                    codeTitleList: [],
                    codeList: [],
                    countryCode: '',
                    employerEdit: false,
                    adminPhone: '',
                    adminFirstName: '',
                    adminLastName: '',
                    adminEmail: '',
                    adminCountryCode: '',
                    effectiveDate: new Date(tomorrow),
                    // empid: '',
                    copyEmpCheck: false,
                });
                // this.getEmployerData();
            })

    }





    getTotalEmpInfo = (empid) => {
        console.log('empid-- -- ', empid);
        axios.get(configurations.baseUrl + '/enrollment/getCountsFormEmployer/' + this.state.empDetails.brokerId)
            .then(response => {
                console.log('empres', response);
                if (response.data.code === 200) {
                    this.setState({
                        countData: response.data.response,
                        // USER_DATA: JSON.parse(sessionStorage.getItem('USER_DATA')),
                        loaderShow: false
                    }, () => console.log("Userdata---", this.state.countData))
                }
            })
    }

    generateUrl(val) {
        this.setState({ loading: true })
        var obj = {
            "clientId": this.state.cid,
            "associationId": this.state.aid,
            "brokerId": this.state.bid,
            'empid': this.state.empid
        }
        axios.post(configurations.baseUrl + '/encrypt/encryptData', obj)
            .then(res => {
                if (res && res.response) {
                    this.setState({ url: this.state.baseUrl + '#state=' + res.response, loading: false })
                }
            });
    }

    setValue(value, isValid, parentDetails) {
        if (value !== undefined && value !== null && isValid) {
            if (parentDetails.name === 'brokerid') {
                this.setState({ bid: value }, () => {
                    this.checkVal('ENCODING')
                })
            } else if (parentDetails.name === 'associationid') {
                this.setState({ aid: value }, () => {
                    this.checkVal('ENCODING')
                })
            } else if (parentDetails.name === 'clientid') {
                this.setState({ cid: value }, () => {
                    this.checkVal('ENCODING')
                })
            } else {
                this.setState({ baseUrl: value }, () => {
                    this.checkVal('ENCODING')
                })
            }
        }
    }

    setUserValue = (value, isValid, parentDetails) => {
        if (parentDetails.name === 'firstname') {
            if (isValid) {
                this.state.fname = value;
            } else {
                this.state.fname = '';
            }
        } else if (parentDetails.name === 'lastname') {
            if (isValid) {
                this.state.lname = value;
            } else {
                this.state.lname = '';
            }
        } else if (parentDetails.name === 'email') {
            if (isValid) {
                this.state.email = value;
            } else {
                this.state.email = "";
            }
        } else if (parentDetails.name === 'phone') {
            if (isValid) {
                this.state.phone = value;
            } else {
                this.state.phone = "";
            }
        }
        this.checkVal('USER');
    }

    checkVal(flag) {
        if (flag === 'ENCODING') {
            if (this.state.cid !== '' && this.state.bid !== '' && this.state.aid !== '' && this.state.baseUrl !== '') {
                this.setState({ genrate: false })
            } else {
                this.setState({ genrate: true })
            }
        } else if (flag === 'USER') {
            if (this.state.fname !== '' && this.state.lname !== '' && this.state.email !== '' && this.state.phone !== '') {
                this.setState({ userValid: false })
            } else {
                this.setState({ userValid: true })
            }
        }

    }

    openMailModal = () => {
        this.setState({
            mailModal: true,
            genrate: false
        })
    }

    logoutPage = () => {
        this.props.history.replace('/login');
    }

    handleMenu = (event) => {

        this.setState({
            openMenu: event.currentTarget
        })
    };
    menuClose = (event) => {

        this.setState({
            openMenu: !event.currentTarget
        })
    };

    sendMail = () => {
    }

    handleEmpDetails = (flag) => {
        this.setState({
            loaderShow: true,
            editEmp: true
        })
        axios.get(process.env.REACT_APP_BASE_URL + '/employer/getEmployerByEmpId/' + this.state.empid)
            .then(response => {
                console.log(response);
                if (response.data.response) {
                    this.setState({
                        id: response.data.response.id,
                        compName: response.data.response.companyName,
                        firstName: response.data.response.firstName,
                        lastName: response.data.response.lastName,
                        email: response.data.response.email,
                        phone: response.data.response.phone,
                        zipCode: response.data.response.zip,
                        state: response.data.response.state,
                        city: response.data.response.city,
                        jobTitle: response.data.response.jobTitle,
                        workEmail: response.data.response.email,
                        street: response.data.response.street,
                        division: response.data.response.division,
                        industry: response.data.response.industry,
                        codeTitle: response.data.response.codeTitle,
                        code: response.data.response.code,
                        effectiveDate: response.data.response.effective_date ? moment(response.data.response.effective_date).format('MMMM DD, YYYY') : null,
                        adminFirstName: response.data.response.adminFirstName,
                        adminLastName: response.data.response.adminLastName,
                        adminEmail: response.data.response.adminEmail,
                        adminPhone: response.data.response.adminPhone,
                        employerContribution: response.data.response.employerContribution,
                        payrollCycle: response.data.response.payrollCycle || 'Weekly',
                        contributionType: response.data.response.contributionType || 'Doller',
                        bankName: response.data.response.employerPayment?.bankName,
                        accountName: response.data.response.employerPayment?.accountName,
                        accountNumber: response.data.response.employerPayment?.accountNumber,
                        accountType: response.data.response.employerPayment?.accountType,
                        routingNo: response.data.response.employerPayment?.routingNo,
                        acknowledgement: response.data.response.acknowledgement,
                        showAcknowledgementModal: !response.data.response.acknowledgement,
                    })
                    let arr = [];
                    // if(response.data.response.email == response.data.response.adminEmail){
                    //     arr.push({key : response.data.response.email, value : response.data.response.email});
                    // }else{
                    //     arr.push({key : response.data.response.email, value : response.data.response.email});
                    //     arr.push({key : response.data.response.adminEmail, value : response.data.response.adminEmail});
                    // }
                    if (response.data.response.email == response.data.response.adminEmail) {
                        arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                        arr.push({ key: response.data.response.email ? response.data.response.email + " " + "(Employer/Administrator)" : '', value: response.data.response.email ? response.data.response.email : '' });

                    } else {
                        arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                        arr.push({ key: response.data.response.email + ' ' + "(Employer)", value: response.data.response.email });
                        arr.push({ key: response.data.response.adminEmail ? response.data.response.adminEmail + " " + "(Administrator)" : '', value: response.data.response.adminEmail ? response.data.response.adminEmail : '' });

                    }

                    this.setState({
                        // employerDetailsObj : obj,
                        viewEmployerModal: flag !== 'didM' ? true : false,
                        loaderShow: false,
                        emp_email_id: arr
                    })
                }
            })
    }

    handleQuoteMail = () => {
        this.setState({
            selectedEmail: '',
            confirmationModal: true
        })
    }

    setEmailId = (value, isValid, parentDetails) => {
        if (isValid) {
            this.state[parentDetails.name] = value;
        } else {
            this.state[parentDetails.name] = "";
        }

        this.setState({
            refresh: true
        })
    }

    sendEmpQuoteMail = () => {
        this.setState({ loaderShow: true, confirmationModal: false });
        axios.get(process.env.REACT_APP_enrollment_base_url + '/plan/sendEmployerQuoteMail/' + this.state.USER_DATA.agentId + '/' + this.state.empid + '/' + this.state.selectedEmail)
            .then(response => {
                console.log(response);
                let errMsg = '';
                if (response.data.code === 200) {
                    errMsg = 'Quote email sent successfully!'
                } else if (response.data.code === 202) {
                    errMsg = 'Age or state not available for employee.'
                } else if (response.data.code === 204) {
                    errMsg = 'Email could not be send due to the following errors.'
                    this.setState({
                        sendQuoteFlag: true,
                        sendQuoteError: response.data.response
                    })
                } else {
                    errMsg = 'Internal Server Error'
                }

                this.setState({
                    msgModal: true,
                    loaderShow: false,
                    errMsg: errMsg,
                    selectedEmail: ''
                });
            })
    }

    nameChangeHandler = (val, isValid, parentObj) => {
        if (isValid) {
            this.setState({
                fullName: val,
                isValidFullName: true,
            }, () => this.checkAuthEnable());
        } else {
            this.setState({
                fullName: "",
                isValidFullName: false,
            }, () => this.checkAuthEnable());
        }
    };


    // update employer details modal text value change
    setEmpValue = (value, isValid, parentDetails) => {
        console.log('setttt');
        console.log(value, '  -  ', this.state[parentDetails.name], '  =  ', parentDetails);

        if (isValid) {
            if (parentDetails.name == 'contributionType') {
                this.state['employerContribution'] = null;
            }
            if (parentDetails.name == 'employerContribution') {
                // if (this.state.contributionType == 'Doller') {
                //     if (value.length > 1) {
                //         value = value.slice(1)
                //     }
                // }
                // else {
                //     if (value.length > 1) {
                //         value = value.slice(0, -1)
                //     }
                // }
                this.state[parentDetails.name] = value
                // this.state[parentDetails.name] = value ? parseFloat(value) : null;
            } else this.state[parentDetails.name] = value;
        } else {
            this.state[parentDetails.name] = "";
        }
        this.setState({
            refresh: true
        }, () => {
            this.checkEnable()
            this.checkAuthEnable()
        });
    }

    checkEnable = () => {
        // && division !== '' && industry !== '' && codeTitle !== '' && code !== ''&& jobTitle !== '' && adminPhone !== '' && phone !== ''&& street !== '' 
        const { compName, zipCode, state, city, street, division, industry, codeTitle, code, firstName, lastName, workEmail, jobTitle, phone, adminPhone, adminFirstName, adminLastName, adminEmail } = this.state;
        if (compName !== '' && zipCode !== '' && state !== '' && city !== '' && firstName !== '' && lastName !== '' && workEmail !== '' && adminFirstName !== '' && adminLastName !== '' && adminEmail !== '') {
            this.setState({
                disableSubmit: false
            })
        } else {
            this.setState({
                disableSubmit: true
            })
        }
    }

    checkAuthEnable = () => {
        // && division !== '' && industry !== '' && codeTitle !== '' && code !== ''&& jobTitle !== '' && adminPhone !== '' && phone !== ''&& street !== '' 
        const { checkBoxFirst, checkBoxSecond, fullName, canvasDone } = this.state;
        console.log('data - - ', fullName);
        if (checkBoxFirst && checkBoxSecond && (fullName != '' || canvasDone)) {
            this.setState({
                disableAuthSubmit: false
            })
        } else {
            this.setState({
                disableAuthSubmit: true
            })
        }
    }

    setEmpDropDownValue = (value, isValid, parentDetails) => {
        this.setState({
            loaderShow: true
        })
        if (isValid) {
            this.state[parentDetails.name] = value;
        } else {
            this.state[parentDetails.name] = "";
        }

        if (parentDetails.name === 'division') {
            this.setState({
                industryList: [],
                codeTitleList: [],
                code: ''
            })
            let obj = {
                "searchKey": "industry",
                "searchValue": value
            }

            this.getSICDetails(obj);
        } else if (parentDetails.name === 'industry') {
            this.setState({
                codeTitleList: [],
                code: ''
            })
            let obj = {
                searchKey: 'codeTitle',
                searchValue: value
            }
            this.getSICDetails(obj);
        } else if (parentDetails.name === 'codeTitle') {
            let findIndex = this.state.codeTitleList.find(obj => obj.key === value);
            if (findIndex) {
                //this.state.code = findIndex.sicCode;
                this.setState({
                    code: findIndex.sicCode,
                    loaderShow: false
                });
            }
        }
        this.setState({
            refresh: true,
        }, () => { this.checkEnable() });
    }

    getSICDetails = (obj) => {
        axios.post(process.env.REACT_APP_BASE_URL + '/employer/getSIC', obj)
            .then(response => {
                let arr = [];
                let data = response.data.response;
                if (obj.searchKey === 'industry') {
                    for (let i = 0; i < data.length; i++) {
                        arr.push({ key: data[i].industry, value: data[i].industry })
                    }
                    this.setState({
                        industryList: arr,
                        loaderShow: false
                    }, () => { this.checkEnable() })
                } else if (obj.searchKey === 'codeTitle') {
                    for (let i = 0; i < data.length; i++) {
                        arr.push({ key: data[i].codeTitle, value: data[i].codeTitle, sicCode: data[i].sicCode })
                    }
                    this.setState({
                        codeTitleList: arr,
                        loaderShow: false
                    }, () => { this.checkEnable() })
                }
            });
    }
    handleDateChange = (date, didMount) => {
        this.setState({
            effectiveDate: date
        }
            , () => {
                let panel = document.getElementById("date-picker-dialog");
                panel.addEventListener("onmouseleave", function () {
                    document.getElementById("date-picker-dialog-label").style.paddingTop = "10px";
                });
            }
        );
    }


    copyEmployerDetails = () => {
        console.log("==Clicked===");
        console.log(this.state.copyEmpCheck);
        if (this.state.copyEmpCheck == false) {
            this.setState({
                copyEmpCheck: true,
                adminFirstName: this.state.firstName,
                adminLastName: this.state.lastName,
                adminEmail: this.state.workEmail,
                adminPhone: this.state.phone,
            }, () => this.checkEnable())

        } else {
            this.setState({
                copyEmpCheck: false,
                adminFirstName: '',
                adminLastName: '',
                adminEmail: '',
                adminPhone: '',
            }, () => this.checkEnable())

        }

    }

    closeEmployerModal = () => {
        let today = new Date();
        let tomorrow = new Date(today);
        if (this.state.USER_DATA.clientId.toString() === '6548' || this.state.USER_DATA.clientId.toString() === '4367'
            || this.state.USER_DATA.clientId.toString() === '5540' || this.state.USER_DATA.clientId.toString() === '4376'
            || this.state.USER_DATA.clientId.toString() === '5541' ||
            this.state.USER_DATA.clientId.toString() === '4377'

        ) {
            if (new Date() < new Date("05/01/2021")) {
                tomorrow.setDate(1);
                tomorrow.setMonth(4);
            } else if (new Date().getDate() === 1 || new Date().getDate() > 1) {
                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 1);
            }
        } else {
            tomorrow.setDate(tomorrow.getDate() + 1);
        }
        this.setState({
            viewEmployerModal: false,
            errMsg: '',
            // compName: '',
            // zipCode: '',
            // state: '',
            // city: '',
            // street: '',
            // division: '',
            // industry: '',
            // codeTitle: '',
            // code: '',
            // firstName: '',
            // lastName: '',
            // workEmail: '',
            // jobTitle: '',
            // phone: '',
            // industryList: [],
            // codeTitleList: [],
            // codeList: [],
            // countryCode: '',
            // adminPhone: '',
            // adminFirstName: '',
            // adminLastName: '',
            // adminEmail: '',
            // adminCountryCode: '',
            // effectiveDate: new Date(tomorrow),
            // employerEdit: false,
            // empid: '',
            // copyEmpCheck: false
        })
    }

    // update employer details modal text value change end namita

    disableWeekends(date, clientId) {
        // return (clientId.toString() === '6548' || clientId.toString() === '4367' || 
        // clientId.toString() === '5540' || clientId.toString() === '4376'|| clientId.toString() === '5541'|| 
        // clientId.toString() === '4377') ? (date.getDate() === 1 ? false : true) : false;
        if (date.getDate() === 1 || date.getDate() === 15) {
            return false
        } else {
            return true
        }


    }

    handleOK = () => {
        this.setState({ msgModal: false, sendQuoteFlag: false, loaderShow: false }, () => this.handleEmpDetails('didM'))
        //   window.location.reload()
    }

    dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(",")[1]);

        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    changeSignType = (e, v) => {
        this.setState({
            signType: v,
            fullName: v === "stylized" ? "" : this.state.fullName,
        });
    };

    handleSignClear = () => {
        this.child.current.clearCanvas();
        this.setState({ canvasDone: false }, () => this.checkAuthEnable())
    };

    handleSignDone = () => {
        this.child.current.cropImageFromCanvas();
        this.child.current.disableCanvas();

        this.setState({
            canvasDone: true,
            canvasChanged: false,
        }, () => this.checkAuthEnable());
        this.nameChangeHandler(this.state.fullName, this.state.fullName !== "");
        const img = this.child.current.convertCanvasToImage();

        this.setState({
            isValidFullName: this.state.fullName === "" ? false : true,
        });

        return img.src;
    };

    uploadImage = async (file) => {
        this.setState({
            loaderShow: true,
        });
        let formData = new FormData();
        formData.append(
            "file",
            file,
            `${new Date().getTime()}.png`
        );

        await axios
            .post(configurations.baseUrl + "/employer/uploadEmployerImage", formData)
            .then((res) => {
                this.setState({
                    loaderShow: false,
                    imgUrl: res.data.response,
                });
                return res.data.response;
            });
    };

    textSignatureToImage = () => {
        let node = document.getElementById("signTextContent");
        return html2canvas(node).then(function (canvas) {
            return canvas.toDataURL("image/png");
        });
    };

    authorize = async () => {
        console.log('--------- brfore ----------');
        this.setState({
            loaderShow: true,
            isSaved: false
        });

        if (this.state.signType === "stylized") {
            const img = await this.textSignatureToImage();
            await this.uploadImage(this.dataURItoBlob(img));
        }

        if (this.state.signType === "scribble") {
            await this.uploadImage(this.dataURItoBlob(this.handleSignDone()));
        }

        // await this.uploadPDF()

        this.setState({ loaderShow: true })
        let data = {
            "empid": this.state.empDetails.empid,
            "authName": this.state.fullName,
            "esign": this.state.imgUrl,
            // "esignPdf": this.state.pdfUrl,
            "acknowledgement": true
        }
        axios.post(configurations.baseUrl + '/employer/updateEmployerAck', data)
            .then(response => {
                console.log('empres', response);
                if (response.data.code === 200) {
                    // window.open("about:blank", "_self");
                    // window.close()
                    this.setState({
                        loaderShow: false,
                        isAcknowledged: true
                    })
                }
            })

    };

    uploadPDF = async (file) => {
        await axios.get(configurations.baseUrl + `/employer/eSignPdf/${this.state.empEmail}`)
            .then(res => {
                console.log('pdgf--', res);

                this.setState({
                    loaderShow: true,
                });
                let formData = new FormData();
                formData.append(
                    "file",
                    file,
                    `${new Date().getTime()}.png`
                );

                axios
                    .post(configurations.baseUrl + "/employer/uploadEmployerEsign", formData)
                    .then((res) => {
                        this.setState({
                            loaderShow: false,
                            pdfUrl: res.data.response,
                        });
                        return res.data.response;
                    });
            })

    };

    render() {
        console.log('------ppppp------ ', this.state.USER_DATA);
        let myDate = moment(this.state.effectiveDate).format('MM') + '/' + moment(this.state.effectiveDate).format('DD') + '/' + moment(this.state.effectiveDate).format('YYYY');
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let futureTomarow = new Date(today);
        //const futureDate = this.state.USER_DATA.clientId.toString() === '6548' ? futureTomarow.setDate(futureTomarow.getDate() + 45) : futureTomarow.setDate(futureTomarow.getDate() + 90);
        let futureDate = new Date(today);

        if (new Date() < new Date("05/01/2021")) {
            futureTomarow.setDate(1);
            futureTomarow.setMonth(4);
            tomorrow.setDate(1);
            tomorrow.setMonth(4);
        } else if (new Date().getDate() === 1 || new Date().getDate() <= 15) {
            futureTomarow.setDate(1);
            futureTomarow.setMonth(today.getMonth() + 2);
            tomorrow.setDate(1);
            tomorrow.setMonth(today.getMonth() + 2);
            futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45)
        } else if (new Date().getDate() > 15) {
            futureTomarow.setDate(15);
            futureTomarow.setMonth(today.getMonth() + 2);
            futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45);
            tomorrow.setDate(15);
            tomorrow.setMonth(today.getMonth() + 2);
        }



        return (
            <div>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                <Header empId={this.state.empDetails?.empid} agentId={this.state.USER_DATA.agentId} clientId={this.state.USER_DATA.clientId} associationId={this.state.USER_DATA.associationId} clientName={this.state.USER_DATA.clientName} bottomMargin={'0px'} isAuth={this.state.showAuthWindow && this.state.acknowledgement} closeAuth={() => this.setState({ showAuthWindow: false })}></Header>
                <div style={{ flexGrow: 1 }}>
                    <Grid container direction="row" style={{ marginBottom: '30px', testAlign: 'center' }}>
                    </Grid>
                </div>
                <div style={{ flexGrow: 1, paddingLeft: '10px', minHeight: '100vh' }}>
                    <Grid container spacing={2} justify='center'>
                        <Grid xs={12} sm={8} item={true} style={{}}>

                            <div style={{ flexGrow: 1, height: 740, backgroundColor: '#ffffff' }}>
                                <Grid container style={{ backgroundColor: '#f8f8f8', padding: '10px' }}>
                                    <Grid item={true} xs={3} sm={3} style={{ textAlign: 'left', padding: '5px' }}>
                                        <div>
                                            <span id={"employerName"} style={customStyle.countLabel}>Employer Name</span>
                                        </div>
                                        <div>
                                            <span style={customStyle.countValue}>{this.state.countData.companyName}</span>
                                        </div>
                                    </Grid>
                                    <Grid item={true} xs={2} sm={2} style={{ textAlign: 'left', padding: '5px' }}>
                                        <div>
                                            <span id={"employerName"} style={customStyle.countLabel}>Employer ID</span>
                                        </div>
                                        <div>
                                            <span style={customStyle.countValue}>{this.state.empDetails?.empid}</span>
                                        </div>
                                    </Grid>
                                    {this.state.isAcknowledged &&
                                        <>
                                            <Grid item={true} xs={2} sm={2} style={{ textAlign: 'left', padding: '5px' }}>
                                                <div>
                                                    <span id={"employerName"} style={customStyle.countLabel}>Total Employees</span>
                                                </div>
                                                <div>
                                                    <span style={customStyle.countValue}>{this.state.countData.total}</span>
                                                </div>
                                            </Grid>
                                            <Grid item={true} xs={2} sm={2} style={{ textAlign: 'left', padding: '5px' }}>
                                                <div>
                                                    <span id={"employerName"} style={customStyle.countLabel}>Total Enrolled</span>
                                                </div>
                                                <div>
                                                    <span style={customStyle.countValue}>{this.state.countData.enrolledCount}</span>
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item={true} xs={1} sm={1} style={{ backgroundColor: '#f8f8f8' }}>

                                    </Grid>
                                    {this.state.isAcknowledged &&
                                        <>
                                            <Grid item xs={2} sm={2} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f8f8f8', justifyContent: 'flex-end' }}>
                                                <Tooltip title="Advice">
                                                    <CrudButton color="primary" aria-label="add" style={{ width: '46px', height: '46px', boxShadow: 'none', marginTop: '7px', backgroundColor: '#f48366' }} onClick={() => this.setState({ showAdviceList: true })} >
                                                        <img src={require('../../../Assets/Images/employer_details_icon.svg')} />
                                                    </CrudButton>
                                                </Tooltip>
                                                <Tooltip title="Invoice">
                                                    <CrudButton color="primary" aria-label="add" style={{ width: '46px', height: '46px', boxShadow: 'none', margin: '7px 10px 0 20px', backgroundColor: '#fdcf85' }} onClick={() => this.setState({ showInvoiceList: true })} >
                                                        <img src={require('../../../Assets/Images/authIcon.svg')} />
                                                    </CrudButton>
                                                </Tooltip>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                {!this.state.isAcknowledged ?
                                    <div container style={{ display: 'flex', flexDirection: 'column', padding: 20, justifyContent: 'space-between', height: 460 }}>
                                        <div>
                                            <Grid container>
                                                <span>Please read and indicate your acceptance of the statements linked below. Then, when you’re ready, click SUBMIT.</span>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "5px" }} id="11">
                                                <Grid item xs={2}>
                                                    <Checkbox
                                                        checked={this.state.checkBoxFirst}
                                                        inputProps={{
                                                            "aria-label": "secondary checkbox",
                                                        }}
                                                        style={{ color: "#533278", marginLeft: -12 }}
                                                        onClick={() => {
                                                            this.setState({ checkBoxFirst: !this.state.checkBoxFirst });
                                                            setTimeout(() => this.checkAuthEnable(), 100);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginLeft: "30px",
                                                        marginTop: "-36px",
                                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    <span>I CONFIRM that the billing account information, that I or a representative submitted to netWell is correct and that I authorize netWell to initiate debit entries from the account provided.</span>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "5px" }} id="11">
                                                <Grid item xs={2}>
                                                    <Checkbox
                                                        checked={this.state.checkBoxSecond}
                                                        inputProps={{
                                                            "aria-label": "secondary checkbox",
                                                        }}
                                                        style={{ color: "#533278", marginLeft: -12 }}
                                                        onClick={() => {
                                                            this.setState({ checkBoxSecond: !this.state.checkBoxSecond });
                                                            setTimeout(() => this.checkAuthEnable(), 100);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginLeft: "30px",
                                                        marginTop: "-36px",
                                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    <span>I have READ, I UNDERSTAND, I ACKNOWLEDGE, I ACCEPT and I AGREE with the employer attestations and agreements on netWell programs and <a href={this.state.pdfLink} target='blank'>Courtesy Payroll Deductions</a>.</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: 35 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="h6"
                                                    component="h3"
                                                    style={customeClasses.questionTitleText}
                                                >
                                                    Authorization for employer
                                                </Typography>
                                                <Sample
                                                    setChild={this.nameChangeHandler.bind(this)}
                                                    name={"FirstName"}
                                                    isValidFullName={this.state.isValidFullName}
                                                    reqFlag={true}
                                                    label={"Authorize this application by typing in your full name"}
                                                    value={this.state.fullName}
                                                    disable={false}
                                                    style={customeClasses.textField}
                                                    length={120}
                                                    fieldType={"holderName"}
                                                    errMsg={"Enter valid full name"}
                                                    helperMsg={
                                                        "By typing my name above, I understand and agree that this form of electronic signature has the same legal force and affect as a manual signature."
                                                    }
                                                    parentDetails={{}}
                                                ></Sample>
                                                <div>
                                                    <Typography
                                                        variant="h6"
                                                        component="h3"
                                                        style={customeClasses.questionTitleText}
                                                    >
                                                        e-signature
                                                    </Typography>
                                                    <RadioGroup
                                                        row
                                                        aria-label="option"
                                                        name="row-radio-buttons-group"
                                                        value={this.state.signType}
                                                        onChange={(event, value) => {
                                                            this.changeSignType(event, value);
                                                            this.setState({
                                                                canvasChanged: false,
                                                                canvasDone: false,
                                                            }, () => this.checkAuthEnable());
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value="stylized"
                                                            control={<PurpleRadio />}
                                                            label="Use stylized script"
                                                        />
                                                        <FormControlLabel
                                                            value="scribble"
                                                            control={<PurpleRadio />}
                                                            label="Let me draw"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "block",
                                                    }}
                                                >
                                                    {this.state.signType === "stylized" ? (
                                                        <>
                                                            <div id="txtsign" style={{ width: "100%", height: "100%" }}>
                                                                <TextSignature
                                                                    id="textSignature"
                                                                    text={this.state.fullName}
                                                                    fontSize={50}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <SignScroll
                                                            ref={this.child}
                                                            onCanvasChange={() =>
                                                                this.setState({
                                                                    canvasChanged: true,
                                                                })
                                                            }
                                                            onCanvasClear={() =>
                                                                this.setState({
                                                                    canvasChanged: false,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                {this.state.signType !== "stylized" ? (
                                                    <div>
                                                        <NextButton
                                                            variant="contained"
                                                            style={customeClasses.viewProgSum}
                                                            onClick={() => {
                                                                this.handleSignClear();
                                                                this.setState({ canvasChanged: false });
                                                            }}
                                                            disabled={this.state.canvasChanged ? false : true}
                                                        >
                                                            CLEAR
                                                        </NextButton>

                                                        <NextButton
                                                            variant="contained"
                                                            style={customeClasses.viewProgSum}
                                                            onClick={() => this.handleSignDone()}
                                                            disabled={this.state.canvasChanged ? false : true}
                                                        >
                                                            DONE
                                                        </NextButton>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                            <Grid xl={2} lg={4} md={5} sm={6} xs={8} item={true} style={customStyle.EnrollNew2Visit}>
                                                {/* <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'authName'} label={'Authorize this by typing in your full name'} value={this.state.authName} disable={false} style={customStyle.textFieldWrp33} fieldType={'authName'} errMsg={'Enter valid nam'} helperMsg={'Answer required'} parentDetails={{ name: 'authName' }}></Sample> */}
                                                <NextButton style={{ margin: '0', backgroundColor: this.state.disableAuthSubmit ? '#f8f8f8' : '#533278', margin: '35px 10px 0px 0px', width: '120px', height: '40px', color: this.state.disableAuthSubmit ? '#000' : '#fff' }} disabled={this.state.disableAuthSubmit} onClick={() => this.authorize()}>SUBMIT</NextButton>
                                            </Grid>
                                        </div>
                                    </div>
                                    :
                                    <div style={this.state.empid !== '' ? customStyle.agentTableHi_92 : customStyle.agentTableHi_100}>
                                        {
                                            (this.state.USER_DATA && this.state.USER_DATA.clientId) &&
                                            <AgentTable empId={this.state.USER_DATA.empid} agentId={this.state.USER_DATA.agentId} clientId={this.state.USER_DATA.clientId} associationId={this.state.USER_DATA.associationId} clientName={this.state.USER_DATA.clientName} defaultEmpid={this.state.USER_DATA.defaultEmpid} employerName={this.state.countData.companyName} refreshTable={() => this.getEmpCounts(this.state.USER_DATA.empid)}></AgentTable>
                                        }
                                    </div>
                                }
                            </div>

                        </Grid>
                        <Grid xs={12} sm={4} item={true}>
                            {(this.state.USER_DATA && this.state.USER_DATA.clientId) &&
                                <AccordonCommon clientId={this.state.USER_DATA.clientId} clientName={this.state.USER_DATA.clientName} agentId={this.state.USER_DATA.agentId} disable={false} empName={(this.state.countData && this.state.countData.companyName) ? this.state.countData.companyName : ''} empid={this.state.empid} forHouseholds={true} />
                            }
                        </Grid>
                    </Grid>


                    <Modal size="lg" show={this.state.mailModal} onHide={(event) => this.setState({ mailModal: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Configure encoded URL</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: '15px' }}>
                            {
                                this.state.loaderShow ? <Loader></Loader> : ''
                            }

                            <div style={customStyle.HomeContainer}>
                                <div style={customStyle.HomeMAinChild}>
                                    <div style={customStyle.HomeTextContiner}>
                                        <div style={{ width: '224px', margin: '10px' }}>
                                            <Sample setChild={this.setValue.bind(this)} reqFlag={true} name={'brokerid'} label={'Broker Id'} value={this.state.bid} disable={false} style={customStyle.textFieldWrp} length={5} fieldType={'treatment'} errMsg={'Enter Broker Id'} helperMsg={'Broker Id Required'} parentDetails={{ name: 'brokerid' }}></Sample>
                                        </div>
                                        <div style={{ width: '224px', margin: '10px' }}>
                                            <Sample setChild={this.setValue.bind(this)} reqFlag={true} name={'associationid'} label={'Association Id'} value={this.state.aid} disable={false} style={customStyle.textFieldWrp} length={5} fieldType={'treatment'} errMsg={'Enter Association Id'} helperMsg={'Association Id Required'} parentDetails={{ name: 'associationid' }}></Sample>
                                        </div>
                                        <div style={{ width: '224px', margin: '10px' }}>
                                            <Sample setChild={this.setValue.bind(this)} reqFlag={true} name={'clientid'} label={'Client Id'} value={this.state.cid} disable={false} style={customStyle.textFieldWrp} length={5} fieldType={'treatment'} errMsg={'Enter Client Id'} helperMsg={'Client Id Required'} parentDetails={{ name: 'clientid' }}></Sample>
                                        </div>
                                    </div>
                                    <div style={customStyle.urlWrp}>
                                        <Sample setChild={this.setValue.bind(this)} reqFlag={true} name={'baseUrl'} label={'Enter your base url here'} value={this.state.baseUrl} disable={true} style={customStyle.textFieldWrpurl} length={5} fieldType={'treatment'} errMsg={'Enter base url'} helperMsg={'Base url Required'} parentDetails={{ name: 'burl' }}></Sample>
                                    </div>
                                    <div style={customStyle.urlWrp}>
                                        <div style={customStyle.HomeLoginUrlWrp}>{this.state.url}</div>
                                    </div>
                                    <div style={customStyle.urngenBtnwrp}>
                                        <div>
                                            <GenBtn1 variant="contained" color="primary" onClick={() => this.generateUrl('login')} disabled={this.state.genrate}>Generate URL</GenBtn1>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <NextButton style={{ margin: '0', marginRight: '10px', width: '96px', height: '40px' }} onClick={this.sendMail()}>Send Mail</NextButton>
                            <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ mailModal: false, loaderShow: false })}>Cancel</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    {/*=================================== Message Model ======================================*/}
                    <Modal size={this.state.sendQuoteFlag ? 'lg' : 'sm'} show={this.state.msgModal} onHide={(event) => this.setState({ msgModal: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            {
                                this.state.loaderShow ? <Loader></Loader> : ''
                            }

                            <div style={customStyle.HomeContainer}>
                                {
                                    this.state.sendQuoteFlag ?
                                        <>
                                            <h6>{this.state.errMsg}</h6>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ background: '#F2F2F2' }}>Record</th>
                                                        <th style={{ background: '#F2F2F2' }}>Name</th>
                                                        <th style={{ background: '#F2F2F2' }}>Missing fields</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.sendQuoteError.map((data) => {
                                                            return <tr>
                                                                <td>{data.record}</td>
                                                                <td>{data.firstName}  {data.lastName}</td>
                                                                <td>{data.missingFields.map((val) => {
                                                                    return <span style={{ textTransform: 'capitalize' }}>{val} <span> , </span></span>
                                                                })}</td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                        :
                                        <>
                                            <div style={customStyle.HomeMAinChild}>
                                                {this.state.errMsg}
                                            </div>
                                        </>
                                }




                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={this.handleOK}>Ok</CustomButton>
                        </Modal.Footer>
                    </Modal>
                    {/*=================================== Message Model ======================================*/}

                    {/*------------ update employer modal namita ------------------------------*/}

                    <Modal size="lg" show={this.state.viewEmployerModal} onHide={(event) => this.setState({ viewEmployerModal: false, loaderShow: false, employerDetailsObj: [] })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Edit Employer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: '15px', height: '330px', overflow: 'auto' }}>
                            {
                                this.state.loaderShow ? <Loader></Loader> : ''
                            }

                            <div style={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justify="center" >
                                    <Grid item xs={12} sm={10}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'CompanyName'} label={'Company Name'} value={this.state.compName} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'company_name'} errMsg={'Enter valid company name'} helperMsg={'Company name required'} parentDetails={{ name: 'compName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Address Details</span>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'zipCode'} label={'Zip Code'} value={this.state.zipCode} disable={false} style={customStyle.textFieldWrpAgent} length={6} fieldType={'zip'} errMsg={'Enter valid zip code'} helperMsg={'Zip code required'} parentDetails={{ name: 'zipCode' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'city'} label={'City'} value={this.state.city} disable={true} style={customStyle.textFieldWrpAgent} length={5} fieldType={'city'} errMsg={'Enter valid city'} helperMsg={'City required'} parentDetails={{ name: 'city' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'state'} label={'State'} value={this.state.state} disable={true} style={customStyle.textFieldWrpAgent} length={25} fieldType={'city'} errMsg={'Enter valid state'} helperMsg={'State required'} parentDetails={{ name: 'state' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'street'} label={'Street, Suite'} value={this.state.street} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'street'} errMsg={'Enter valid street'} parentDetails={{ name: 'street' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Employer Contact Details</span>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'fistName'} label={'First Name'} value={this.state.firstName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'firstName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'lastName'} label={'Last Name'} value={this.state.lastName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'lastName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'jobTitle'} label={'Job title'} value={this.state.jobTitle} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'company_name'} errMsg={'Enter valid job title'} parentDetails={{ name: 'jobTitle' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'workEmail'} label={'Work Email'} value={this.state.workEmail} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid work email'} helperMsg={'Work email required'} parentDetails={{ name: 'workEmail' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'phoenNumber'} label={'Phone Number'} value={this.state.phone} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'phone'} errMsg={'Enter valid phone number'} parentDetails={{ name: 'phone' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                required
                                                onBlur={() => this.setState({ birthDtFocus: true })}
                                                onMouseOver={() => this.setState({ birthDt: true })}
                                                onMouseLeave={() => this.setState({ birthDt: false })}
                                                autoComplete='off'
                                                margin="none"
                                                id="date-picker-dialog"
                                                label="Select Program Effective Date"
                                                format="MM/dd/yyyy"
                                                error={this.state.dateErr} //&&!this.state.todayDateValid
                                                helperText={this.state.dateErr ? 'Enter valid date' : ''} //this.state.todayDateValid?'Date Required':
                                                value={myDate} //this.state.todayDateValid?null:
                                                onFocus={e => e.target.blur()}
                                                onCopy={this.handlerCopy}
                                                onPaste={this.handlerCopy}
                                                inputProps={{ style: { fontSize: '18px', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', paddingLeft: '11px', paddingRight: '10px', marginTop: '11px', '&:focus': { outline: 'none' }, color: !this.state.birthDt ? 'grey' : '#533278' } }}
                                                InputLabelProps={{ style: { paddingLeft: 10, paddingRight: 10, paddingTop: 12, color: !this.state.birthDtFocus ? 'grey' : this.state.birthDt ? '#533278' : 'grey' } }}//|| !this.state.todayDateValid
                                                onChange={this.handleDateChange.bind(this)}
                                                variant="filled"
                                                // onMouseEnter={this.handleHover}
                                                TextFieldComponent={CssTextField}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ width: '100%' }}
                                                shouldDisableDate={(e) => this.disableWeekends(e, this.state.USER_DATA.clientId)}
                                                minDate={new Date(tomorrow)}
                                                maxDate={new Date(futureDate)}
                                            />
                                            <span id='bd' style={customStyle.EnrollNew2Span}></span>

                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <span style={{ marginRight: '20px' }}>Health Administrator Contact Details</span>
                                        <Checkbox
                                            checked={this.state.copyEmpCheck}
                                            inputProps={{
                                                'aria-label': 'secondary checkbox',
                                            }}
                                            style={{ color: '#533278' }}
                                            onClick={this.copyEmployerDetails}
                                        />
                                        <span>Same as above</span>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'adminFistName'} label={'First Name'} value={this.state.adminFirstName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'adminFirstName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'adminLastName'} label={'Last Name'} value={this.state.adminLastName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'adminLastName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'eeail'} label={'Email'} value={this.state.adminEmail} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid email'} helperMsg={'Email required'} parentDetails={{ name: 'adminEmail' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'phoneNumber'} label={'Phone Number'} value={this.state.adminPhone} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'phone'} errMsg={'Enter valid phone number'} parentDetails={{ name: 'adminPhone' }}></Sample>
                                    </Grid>

                                    <Grid item xs={12} sm={10} style={{ marginTop: 15, marginBottom: -15 }}>
                                        <span style={customStyle.textBold}>Payroll Cycle</span>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="days"
                                                    name="row-radio-buttons-group"
                                                    value={this.state.payrollCycle}
                                                    onChange={(event) => this.setState({ payrollCycle: event.target.value })}
                                                >
                                                    <FormControlLabel
                                                        value="Weekly"
                                                        control={<PurpleRadio />}
                                                        label="Weekly"
                                                    />
                                                    <FormControlLabel
                                                        value="BiWeekly"
                                                        control={<PurpleRadio />}
                                                        label="Every Two Weeks"
                                                    />
                                                    <FormControlLabel
                                                        value="Monthly"
                                                        control={<PurpleRadio />}
                                                        label="Monthly"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Employer Contribution</span>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={{ marginTop: -15, marginBottom: -15 }}>
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="days"
                                                    name="row-radio-buttons-group"
                                                    value={this.state.contributionType}
                                                    onChange={(event) => this.setState({ contributionType: event.target.value, employerContribution: '' })}
                                                >
                                                    <FormControlLabel
                                                        value="Doller"
                                                        control={<PurpleRadio />}
                                                        label="Fixed Amount"
                                                    />
                                                    <FormControlLabel
                                                        value="Percentage"
                                                        control={<PurpleRadio />}
                                                        label="Percentage of Monthly Contribution"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Grid item xs={12} sm={6}>
                                            <Sample setChild={this.setEmpValue.bind(this)} name={'employerContribution'} label={`${!this.state.employerContribution ? this.state.contributionType == 'Doller' ? '$0' : '0%' : ''}`} value={this.state.employerContribution} disable={false} style={customStyle.textFieldWrpAgent} length={60} fieldType={'employerContribution'} errMsg={'Enter valid amount'} helperMsg={'Employer Contribution required'} parentDetails={{ name: 'employerContribution' }}></Sample>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Setup ACH</span>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Bank_Name'} label={'Bank Name'} value={this.state.bankName} disable={false} style={customStyle.textFieldWrpAgent} length={60} fieldType={'bank_name'} errMsg={'Enter valid bank name'} helperMsg={'Bank name required'} parentDetails={{ name: 'bankName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Account_Name'} label={'Name on Account'} value={this.state.accountName} disable={false} style={customStyle.textFieldWrpAgent} length={120} fieldType={'accountName'} errMsg={'Enter valid account name'} helperMsg={'Name on account required'} parentDetails={{ name: 'accountName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Routing_Number'} label={'Routing Number'} value={this.state.routingNo} disable={false} style={customStyle.textFieldWrpAgent} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={'Routing number required'} parentDetails={{ name: 'routingNo' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Account_Number'} label={'Account Number'} value={this.state.accountNumber} disable={false} style={customStyle.textFieldWrpAgent} length={26} fieldType={'accountNumber'} errMsg={'ccount number must be 4 and up to 26 digits'} helperMsg={'Account number required'} parentDetails={{ name: 'accountNumber' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Grid item xs={12} sm={6}>
                                            <CommonDropDwn setChild={this.setEmpValue.bind(this)} name={"Account_Type"} account={true} label={"Account Type"} value={this.state.accountType} disable={false} style={customStyle.dropDown} fieldType={"dropDwn"} helperMsg={"Select account type"} List={this.state.accountTypes} parentDetails={{ name: "accountType" }}></CommonDropDwn>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </div>



                        </Modal.Body>
                        <Modal.Footer>
                            <NextButton style={{ margin: '0', marginRight: '10px', width: '95px', height: '40px', backgroundColor: '#4782c4' }} disabled={this.state.disableSubmit} onClick={() => this.addEmployer()}>UPDATE</NextButton>
                            <CustomButton style={{ height: '40px', backgroundColor: '#4782c4', color: "#fff" }} onClick={() => this.closeEmployerModal()}>Cancel</CustomButton>
                        </Modal.Footer>
                    </Modal>


                    {/*------------ update employer modal end------------------------------*/}

                    <Modal size="xs" show={this.state.confirmationModal} backdrop="static" centered>
                        <Modal.Header style={customStyle.modal_header}>
                            <Modal.Title>Send Quote</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ margin: '10px', textAlign: 'left', fontFamily: 'Roboto, Arial, Helvetica, sans-serif' }}>


                            {
                                this.state.countData && this.state.countData.total ?
                                    <CommonDropDwn setChild={this.setEmailId.bind(this)} reqFlag={true} name={'Email'} label={"Please select recipient's email address"} value={this.state.selectedEmail} fieldType={'dropDwn'} disable={false} style={customStyle.dropDown} List={this.state.emp_email_id} errMsg={'Select valid email'} helperMsg={'Email required'} parentDetails={{ name: 'selectedEmail' }}></CommonDropDwn>


                                    :
                                    <h6>No census data available!</h6>
                            }
                        </Modal.Body>
                        <Modal.Footer style={{ alignItems: 'right' }}>
                            {
                                this.state.countData && this.state.countData.total ?
                                    <CustomButton style={{ width: '90px', height: '40px' }} disabled={this.state.selectedEmail === ''} onClick={() => this.sendEmpQuoteMail()}>Confirm</CustomButton>
                                    :
                                    null
                            }
                            <CustomButton style={{ marginLeft: '10px', width: '70px', height: '40px' }} onClick={() => { this.setState({ confirmationModal: false, loaderShow: false, selectedEmail: '' }) }}>Cancel</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="md" show={this.state.showAcknowledgementModal} onHide={(event) => this.setState({ showAcknowledgementModal: false, loaderShow: false }, () => window.location.replace(window.location.origin + '/manage-employers'))} backdrop='static' centered>
                        <Modal.Header style={customStyle.modal_header} closeButton>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: '15px' }}>
                            {
                                this.state.loaderShow ? <Loader></Loader> : ''
                            }

                            <div style={customStyle.HomeContainer}>
                                <div style={customStyle.HomeMAinChild}>
                                    <text>The employer hasn't acknowledged the attestations and agreements with respect to Courtesy Payroll Deductions. This is a prerequisite for employees to be added for enrollment.</text>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ showAcknowledgementModal: false, loaderShow: false }, () => window.location.replace(window.location.origin + '/manage-employers'))}>Close</CustomButton>
                            <CustomButton style={{ height: '40px', marginLeft: '10px' }} onClick={() => this.setState({ showAcknowledgementModal: false, loaderShow: false, showAuthWindow: true })}>Acknowledge Now</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size={'lg'} show={this.state.showInvoiceList} onClose={(event) => this.setState({ showInvoiceList: false, loaderShow: false })} onHide={(event) => this.setState({ showInvoiceList: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Invoices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            {
                                !this.state.invoiceList[0] ?
                                    <div>
                                        <text>No invoices to be shown!</text>
                                    </div>
                                    :
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <div className="">
                                                    <MUIDataTable
                                                        data={this.state.invoiceList}
                                                        columns={this.invoiceColumns}
                                                        options={this.invoiceOptions}
                                                    />
                                                </div>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={(event) => this.setState({ showInvoiceList: false, loaderShow: false })}>CLOSE</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size={'lg'} show={this.state.showAdviceList} onClose={(event) => this.setState({ showAdviceList: false, loaderShow: false })} onHide={(event) => this.setState({ showAdviceList: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Advices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            {
                                !this.state.adviceList[0] ?
                                    <div>
                                        <text>No advices to be shown!</text>
                                    </div>
                                    :
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <div className="">
                                                    <MUIDataTable
                                                        data={this.state.adviceList}
                                                        columns={this.adviceColumns}
                                                        options={this.adviceOptions}
                                                    />
                                                </div>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={(event) => this.setState({ showAdviceList: false, loaderShow: false })}>CLOSE</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size={'xl'} show={this.state.showInvoice} onClose={(event) => this.setState({ showInvoice: false, loaderShow: false })} onHide={(event) => this.setState({ showInvoice: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Invoice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            <iframe
                                src={this.state.invoiceUrl}
                                title="Common Module"
                                style={{
                                    width: '100%',
                                    height: "500px",
                                    border: 'none',
                                    margin: 'auto'
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={(event) => this.setState({ showInvoice: false, loaderShow: false })}>CLOSE</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size={'xl'} show={this.state.showAdvice} onClose={(event) => this.setState({ showAdvice: false, loaderShow: false })} onHide={(event) => this.setState({ showAdvice: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header} closeButton>
                            <Modal.Title>Advice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            <iframe
                                src={this.state.adviceUrl}
                                title="Common Module"
                                style={{
                                    width: '100%',
                                    height: "500px",
                                    border: 'none',
                                    margin: 'auto'
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={(event) => this.setState({ showAdvice: false, loaderShow: false })}>CLOSE</CustomButton>
                        </Modal.Footer>
                    </Modal>

                    {/* <Modal size={'lg'} show={this.state.isAcknowledged} onHide={(event) => this.setState({ isAcknowledged: false, loaderShow: false })} centered backdrop='static'>
                        <Modal.Header style={styles.modal_header}>
                            <Modal.Title>Thank You</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={this.state.sendQuoteFlag ? customStyle.tableSendquoteErrorList : customStyle.tableSendquote}>
                            <div>
                                <text>Thank you for completing the authorization for netWell Healthshare to offer programs to your employees. Please contact your representative to begin enrolling your employees and their families. Please come back here in a few days to view the status of enrollments.</text>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton style={{ height: '40px' }} onClick={() => this.logoutPage()}>Logout</CustomButton>
                        </Modal.Footer>
                    </Modal> */}
                </div>
                <div style={{ fontSize: '11px', marginTop: '15px', fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}>
                    <Footer />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        agentId: state.agentId,
        clientId: state.clientId,
        associationId: state.associationId,
        clientName: state.clientName
    }
}

export default connect(mapStateToProps)(Prospect);

export const getMuiTheme = () => createMuiTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                paddingLeft: '7px ',
                paddingBottom: '5px',
                paddingRight: '3px',
                paddingTop: '5px',
                fontSize: '14px',
                color: '#333333',
            },
            responsiveBase: {
                padding: '25px'
            },
        },
        MUIDataTable: { responsiveBase: { padding: '23px' } },
        MuiTableRow: {
            root: {
                '&$selected': {
                    backgroundColor: '#ebf2ff',
                },
                '&$hover': {
                    cursor: 'unset !important'
                }
            }
        },
        MuiTypography: { h6: { fontWeight: 'bold', marginBottom: '-28px', marginLeft: '-10px', marginRight: '50px' } },
        MuiToolbar: { gutters: { paddingLeft: '5px' }, regular: { minHeight: '0px' } },
        MuiTableCell: { root: { borderBottom: '1px solid rgb(0,0,0)', padding: '8px' }, footer: { borderBottom: 'none' } },
        MuiDataTablePagination: { borderBottom: 'none' },
        MuiTableCellHead: {
            fontWeight: 'bold'
          },
          MuiButton: {
            root: {
              fontWeight: 'bold',
            },
        },
        MUIDataTableHeadCell: {
            root: {
                background: 'blue',
                fontWeight: 'bold',
                // padding:'5px'
                paddingLeft: '15px',
                minWidth: '150px'
            },
            MUIDataTable: {
                responsiveBase: {
                    padding: '23px'
                },
            },
        },
    }
})
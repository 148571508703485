import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {Auth} from "aws-amplify";
import Header from "../Headers/Header";
import AgentHome from "./agentHome";
import axios from "axios";
import configurations from "../../../configurations";
import Loader from "../../loader";


class RootComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loaderShow : false,
            userLoggedIn : false,
            agentId : "",
            clientId : "",
            associationId : "",
            clientName : "",
            defaultEmpid : '',
            parentBrokerId:"",
            parentClientId:"",
        }
    }
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidMount() {   
        this.setState({
            loaderShow: true
        });
    
     let curUser =  Auth.currentAuthenticatedUser();
     console.log(curUser)
     Auth.signOut();
     localStorage.clear();
     sessionStorage.clear();
     curUser =  Auth.currentAuthenticatedUser();
     this.props.history.push("/login");
     console.log(curUser)

     
}

    parseJwt = (id_token) => {

        let base64Url = id_token.split('.')[1];

        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        let jsonPayload = decodeURIComponent(

            atob(base64)

                .split('')

                .map(function (c) {

                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)

                })

                .join('')

        );

        let token = JSON.parse(jsonPayload);
        return token;
    };

    render() {
        
        return(
            <div>
                {
                    this.state.userLoggedIn ?
                    <div>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        <Header  agentId={this.state.agentId} clientId={this.state.clientId} associationId={this.state.associationId} clientName={this.state.clientName} bottomMargin={'30px'}></Header>
                        <AgentHome parentClientId={this.state.parentClientId} parentBrokerId={this.state.parentBrokerId} agentId={this.state.agentId} clientId={this.state.clientId} associationId={this.state.associationId} clientName={this.state.clientName} defaultEmpid={this.state.defaultEmpid}></AgentHome>
                    </div> :null
                }

            </div>
        )

    }
}
const mapStateToProps = state => {
    return {
        agentId: state.agentId,
        clientId: state.clientId,
        associationId: state.associationId,
        clientName : state.clientName
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setUserData: (agentId, clientId, associationId, clientName) => dispatch({ type: 'SET_USER_DATA', agentId: agentId, clientId: clientId, associationId: associationId, clientName : sessionStorage.getItem('clientName') })
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(RootComponent));